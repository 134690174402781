import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  claimsAdminRole,
  canViewOperatorConsultantContent,
} from '../AuthUtils';
import useSelectedLocation from './useSelectedLocation';
import { routeToNewApp } from '../redirectUtil';

const whiteList = ['nancy.easterling', 'ben.collier'];

const useLvrAccess = () => {
  const history = useHistory();
  const { locationNumber } = useParams();
  const { authState } = useOktaAuth();
  const { loading, isLicenseeLocation } = useSelector(
    (state) => state.location,
  );
  const userToken = useSelector((state) => state.auth.userToken);
  const selectedLocation = useSelectedLocation();

  const { claims } = authState.idToken;

  // Redirect to landing page if not a licensee location
  useEffect(() => {
    if (loading || !selectedLocation) return;

    if (isLicenseeLocation !== null && !isLicenseeLocation) {
      routeToNewApp(`/${selectedLocation.locationNumber}/landing`);
    }
  }, [history, isLicenseeLocation, loading, locationNumber, selectedLocation]);

  const checkClaim = (claimFunction) =>
    authState?.accessToken && claimFunction(authState.accessToken.claims);

  const isTeamMember = userToken?.userType === 'Team Member';
  const isOperator = userToken?.userType === 'Operator';
  const isOpenLocation = selectedLocation?.location?.openStatus === 'open';
  const hasAdminRole = checkClaim(claimsAdminRole);
  const hasOCNotesAccess = checkClaim(canViewOperatorConsultantContent);
  const isWhiteListed =
    whiteList.findIndex((email) => claims.email.startsWith(email)) !== -1;
  return {
    hasOCNotesAccess,
    hasLvrFormAccess:
      isWhiteListed ||
      (isOpenLocation && !isTeamMember && (isOperator || hasAdminRole)),
  };
};

export default useLvrAccess;
